@use "sass:math";

.here-to-help {
    padding: 88px 0 92px 0;
    margin: 0 $site-gutter;

    @include breakpoint(phablet) {
        padding: 52px 0 54px 0;
        margin: 0 $site-gutter--mobile;
    }
}

.here-to-help__header {
    margin-bottom: 32px;

    @include breakpoint(phablet) {
        margin-bottom: 16px;
    }
}

.here-to-help__title {
    @include medium-title;
    @include rem(32px);

    @include breakpoint(phablet) {
        @include rem(24px);
    }
}

.here-to-help__grid {
    $gap: 12px;

    display: grid;
    grid: auto / repeat(4, minmax(0, 1fr));
    column-gap: $gap;
    row-gap: $gap * 2;

    @include breakpoint(phablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 20px;
        row-gap: 30px;
    }
}

.here-to-help__card {
    a {
        text-decoration: none;
        color: $c-black;
    }
}

.here-to-help__card-image {
    margin-bottom: 15px;
    @include aspect(math.div(1, 1));

    img {
        max-width: 100%;
        height: auto;
    }
}

.here-to-help__card-title {
    @include font(24px, 32px);
    text-decoration: none;

    a:hover & {
        text-decoration: underline;
    }

    @include breakpoint(phablet) {
        @include font(18px, 24px);
    }
}
